import { useState, useCallback, useEffect, useRef } from "react";
import styles from "../css/bottomMapControls.module.css";
import { useFilterContext } from "./ContextManager";

import { mapStyles } from "../theme/mapStyles";

export function MapStyleToggle({ currentStyle, onStyleChange, map }) {
  const { updateFilters } = useFilterContext();
  const [isOpen, setIsOpen] = useState(false);
  const [mapState, setMapState] = useState({
    typeId: map?.getMapTypeId(),
    currentStyle: currentStyle,
  });
  const menuRef = useRef(null);
  const lastNonSatelliteColorIndex = useRef(0);

  // Combined map state listener
  useEffect(() => {
    if (!map) return;

    const updateMapState = () => {
      const typeId = map.getMapTypeId();
      const style =
        typeId === "satellite"
          ? { ...mapStyles.satellite, mapId: mapStyles.light.id }
          : Object.values(mapStyles).find((style) => style.id === map.mapId) ||
            mapStyles.light;

      setMapState({
        typeId,
        currentStyle: style,
      });
    };

    // Initial state
    updateMapState();

    // Single listener for map changes
    const listener = map.addListener("maptypeid_changed", updateMapState);
    return () => listener.remove();
  }, [map]);

  // Click outside handler
  useEffect(() => {
    if (!isOpen) return;

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setTimeout(() => {
          setIsOpen(false);
        }, 200);
      }
    };

    const handler = () => {
      document.addEventListener("click", handleClickOutside);
    };

    requestAnimationFrame(handler);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      clearTimeout();
    };
  }, [isOpen]);

  const handleStyleChange = useCallback(
    (key) => {
      const style = mapStyles[key];
      const isSatelliteActive = map?.getMapTypeId() === "satellite";

      if (style.type === "satellite") {
        map?.setMapTypeId(isSatelliteActive ? "roadmap" : "satellite");
        updateFilters((prev) => {
          // Store the current color index before switching to satellite
          if (!isSatelliteActive) {
            lastNonSatelliteColorIndex.current = prev.colorIndex;
          }
          return {
            ...prev,
            colorIndex: isSatelliteActive
              ? lastNonSatelliteColorIndex.current
              : 3,
          };
        });
      } else {
        if (isSatelliteActive) {
          map?.setMapTypeId("roadmap");
        }
        onStyleChange(key);
      }

      setTimeout(() => {
        setIsOpen(false);
      }, 300);
    },
    [map, onStyleChange, updateFilters]
  );

  return (
    <div
      ref={menuRef}
      className={`${styles.mapStyleToggle} ${
        isOpen ? styles.mapStyleToggleOpen : ""
      }`}
      onClick={() => setIsOpen(!isOpen)}
    >
      {!isOpen && (
        <img
          src={
            mapState.typeId === "satellite"
              ? mapStyles.satellite.preview
              : mapState.currentStyle?.preview || mapStyles.light.preview
          }
          alt={
            mapState.typeId === "satellite"
              ? "Satellite"
              : mapState.currentStyle?.name || "Map Style"
          }
          className={styles.styleIcon}
        />
      )}
      <div className={styles.buttonContainer}>
        {Object.entries(mapStyles).map(([key, style]) => (
          <button
            key={key}
            className={`${styles.styleButton} ${
              (style.type === "satellite" && mapState.typeId === "satellite") ||
              mapState.currentStyle?.id === style.id
                ? styles.active
                : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              handleStyleChange(key);
            }}
          >
            <img
              src={style.preview}
              alt={style.name}
              className={styles.stylePreview}
            />
            <span className={styles.styleName}>{style.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
}
