import { useFilterContext } from "../../ContextManager";
import FilterTooltip from "../FilterTooltip/FilterTooltip";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

export function ClearCountriesButton() {
  const { updateFilters } = useFilterContext();
  const clearCountries = () => {
    updateFilters((prevFilters) => ({
      ...prevFilters,
      country: [],
    }));
  };
  return (
    <FilterTooltip id="clearAllCountries">
      <IconButton
        variant="contained"
        size="small"
        onClick={clearCountries}
        sx={{ fontSize: "0.5rem", padding: "1px", margin: "0 0 8px 0" }}
      >
        <DeleteIcon />
      </IconButton>
    </FilterTooltip>
  );
}
