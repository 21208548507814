import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

// Define a mapping from IDs to tooltip texts
const tooltips = {
  distance: "Show routes within the set range.",
  length: "Show airports with at least one runway within the set range.",
  large:
    "Airport with scheduled major airline service with millions of passengers/year or major military base.",
  medium:
    "Airport with scheduled regional airline service, or regular general aviation or military traffic.",
  small:
    "Airport with little or no scheduled service, light general aviation traffic.",
  seaplane:
    "Docking/fueling area for seaplanes, with no facilities for land aircraft.",
  scheduledairport: "Show airports with scheduled flights.",
  noscheduledairport: "Show airports without scheduled flights.",
  airportpax: "Show airports with a set minimum annual passengers.",

  domestic: "Show possible domestic routes.",
  international: "Show possible international routes.",
  scheduledroute: "Filter for scheduled routes.",
  pso: "Filter for public service obligation routes.",
  routepax: "Show routes with a set minimum annual passengers.",

  compare:
    "Compare city to city travel times between airplane and car/public transport. Airplane time includes driving time to and from city center.",
  driving: "Compare airplane to car.",
  publictransport:
    "Compare airplane to public transport, the fastest available option between train, bus etc. is used.",
  saved:
    "Set time to show routes where flying saves at least the set amount of time compared to driving/public transport.",
  ratio:
    "Set ratio to show routes where flying is at least the set amount faster than driving/public transport",
  speed: "Set average flight speed of airplane in km/h.",
  departure: "Time the passenger spends at airport before departure.",
  arrival: "Time the passenger spends at airport after arrival.",
  customsection: "Custom user defined airports.",
  customairport: "Show custom airports.",
  customroutes: "Show routes connecting to custom airports",
  clearAllCountries: "Clear All Countries",

  // Add new tooltip for airport identifiers
  airportIdentifier: (airport) => {
    if (!airport) return "";
    const parts = [];
    if (airport.airportId) parts.push(airport.airportId);
    if (airport.iataCode) parts.push(airport.iataCode);
    return parts.join(" | ");
  },

  // Updated profile tooltips with cleaner format
  planeProfile: (profileId) => {
    const profiles = {
      default: `Min runway: 750m
Max distance: 200km
Speed: 250km/h`,
      elflyNoemi: `Min runway: 750m
Max distance: 200km
Speed: 250km/h`,
      heartES30: `Min runway: 1100m
Max distance: 400km
Speed: 350km/h`,
    };
    return profiles[profileId] || "";
  },
};

const FilterTooltip = ({ id, children, airport }) => {
  const title =
    typeof tooltips[id] === "function" ? tooltips[id](airport) : tooltips[id];

  // Custom timing for plane profiles
  const enterDelay = id === "planeProfile" ? 350 : 300;
  const enterNextDelay = id === "planeProfile" ? 350 : 300;
  const transitionTimeout = id === "planeProfile" ? 350 : 300;

  return (
    <Tooltip
      disableInteractive
      enterDelay={enterDelay}
      enterNextDelay={enterNextDelay}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: transitionTimeout }}
      title={title}
      placement="top-start"
    >
      {children}
    </Tooltip>
  );
};

export default FilterTooltip;
