//Material UI
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import style from "../css/contextMenu.module.css";
export const ContextMenu = ({
  visible,
  x,
  y,
  onAddCircle,
  onRemoveCircle,
  updateRadius,
  radius,
  openCustomAirportForm,
}) => {
  if (!visible) return null;

  const handleInputChange = (event) => {
    updateRadius(event.target.value);
  };

  return (
    <div className={style.container} style={{ top: y, left: x }}>
      <div className={style.sliderContainer}>
        <Typography
          id="range-slider"
          className={style.title}
        >
          Radius (km)
        </Typography>
        <Slider
          size="small"
          step={10}
          min={0}
          max={400}
          value={radius}
          aria-label="Small"
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          onChange={handleInputChange}
        />
      </div>
      <ul className={style.list}>
        <li className={style.listItem} onClick={onAddCircle}>
          Add Range Circle
        </li>
        <li className={style.listItem} onClick={onRemoveCircle}>
          Remove Range Circle
        </li>
        <li className={style.listItem} onClick={openCustomAirportForm}>
          Add Custom Airport
        </li>
      </ul>
    </div>
  );
};
