import { useState, useEffect } from "react";
import style from "./../css/loadingScreen.module.css";
import logo from "./../assets/logo/NEA-light-vertical_medium.png";

export function LoadingScreen() {
  // State to control the visibility of the loading screen
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Set a timeout to match the animation duration
    const timer = setTimeout(() => {
      setIsVisible(false); // Hide the loading screen after a set amount of time
    }, 10000); // Duration should match your longest animation

    return () => clearTimeout(timer); // Clean up the timeout if the component unmounts
  }, []); // Empty dependency array means this runs only once on mount

  if (!isVisible) {
    return null; // Don't render the component if not visible
  }

  return (
    <div className={style.loadingContainer}>
      <div className={style.logoWrapper}>
        <img className={style.logo} src={logo} alt="NEA Logo" />
        <div className={style.gradientOverlay}></div>
      </div>
    </div>
  );
}
