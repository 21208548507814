import { useState, useEffect, useRef, useCallback } from "react";
import { useFilterContext } from "./ContextManager";
import { ContextMenu } from "./ContextMenu";
import { SearchBar } from "./UX/SearchBar/SearchBar.jsx";
import { MapMarkerTooltip } from "./UX/MapMarkerTooltip/MapMarkerTooltip";
import { PolylineTooltip } from "./UX/PolylineTooltip/PolylineTooltip";
import { CustomAirportForm } from "./CustomAirportForm.jsx";
import { LoadDataConfirmationModal } from "./LoadDataConfirmationModal.jsx";
import { CustomUserButton } from "./UX/ClerkUserButton/ClerkUserButton.jsx";
import { BottomMapControls } from "./BottomMapControls.jsx";

//CSS
import style from "../css/mapComponent.module.css";

// Map Styles
import { mapStyles } from "../theme/mapStyles";

//Utility functions
import {
  delay,
  debounce,
  calculateMultiplier,
  centerMapOnPolyline,
  centerMapOnPoint,
} from "../utils/utilityFunctions";

import { decompressObjectFromURL } from "../data/schemas/filterCodes";

//SVG
import { createAdvancedMarkerSvg } from "../assets/svg/advancedMarkerSvg.jsx";

//geojson
import { handleGeoJSON } from "../utils/variables";

//Google Maps Api
import { Loader } from "@googlemaps/js-api-loader";

//ENV
const MAPS_KEY = import.meta.env.VITE_MAPS_KEY;

export function MapComponent({ map, updateMap }) {
  //context provider variables
  const {
    filters,
    updateFilters,
    filteredAirportsResult,
    filteredRoutesResult,
    selectedAirport,
    onSelectAirport,
    updateModalState,
    airportIdMap,
    updateLastMapCenter,
    updateLastMapZoom,
    updateApiLoader,
    selectedRoute,
    onSelectRoute,
    updateRouteModalState,
    customAirportForm,
    updateCustomAirportForm,
    updateEditingAirport,
    newCustomAirportCount,
    restoreDefaultCount,
    searchBarIsOpen,
    updateSearchBarIsOpen,
  } = useFilterContext();

  const mapRef = useRef(null);

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    lat: 0,
    lng: 0,
  });
  const [rangeCircle, setRangeCircle] = useState(null); //circle for visualizing range from a center point
  const [radius, setRadius] = useState(200); // radius of the range circle (km)

  const [activeMarker, setActiveMarker] = useState(null); //which airport marker is being interacted with
  const [activeMarkerData, setActiveMarkerData] = useState(null); //updated activeMarker data, for use in marker tooltips
  const [markersState, setMarkersState] = useState(null); // tracks when markers are rendered
  const [isMarkerActive, setIsMarkerActive] = useState(false); //if a marker is hovered over or clicked
  const [activePolyline, setActivePolyline] = useState(null); //which route/polyline is being interacted with
  const [currentPolyline, setCurrentPolyline] = useState(null); //used to change styling of polyline while hovering over both a polyline and a marker
  const [isPolylineActive, setIsPolylineActive] = useState(false); //if a polyline is hovered over or clicked
  const [polylinesState, setPolylinesState] = useState(null); // tracks when polylines are rendered
  const [mousePos, setMousePos] = useState({}); //where polyline tooltip is rendered when hovering over a polyline
  const [isTooltipVisible, setIsTooltipVisible] = useState(false); //tracks visibilty of tooltip, set to false when dragging/panning the map to close all tooltips while dragging
  const [isMapDragging, setIsMapDragging] = useState(false);
  const [customAirportCoords, setCustomAirportCoords] = useState(null);
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [geoJsonMousePos, setGeoJsonMousePos] = useState(null);

  const [markerColors, setMarkerColors] = useState({
    small: "#fff",
    medium: "#fff",
    large: "#fff",
    seaplane: "#fff",
    custom: "#fff",
    domestic: "#fff",
    international: "#fff",
    customRoute: "#fff",
    commercialRoute: "#fff",
    psoRoute: "#fff",
  });

  const googleMapRef = useRef(null);
  // Use a Map object to keep track of markers by airportId
  const markersRef = useRef(new Map());
  const polylinesRef = useRef([]);
  const overlayRef = useRef(null);
  const userSizeMultiplierRef = useRef(1);
  const currentZoomRef = useRef(null);
  const selectedPolylineRef = useRef(null);
  const hoveredPolylineRef = useRef(null);
  const previousSelectedRouteRef = useRef(null); // Store the previous route ID
  const selectedMarkerRef = useRef(null);

  const hoverMultiplier = 1.5; // Marker icon mouseover size multiplier

  // useRef to to avoid async problems whenever isMarkerActive changes. It is used to stop mouseover event on polylines under a map marker if a map marker is being hovered over
  const isMarkerActiveRef = useRef(isMarkerActive);
  useEffect(() => {
    isMarkerActiveRef.current = isMarkerActive;
  }, [isMarkerActive]);

  const radiusRef = useRef(radius);
  useEffect(() => {
    radiusRef.current = radius;
  }, [radius]);

  // Keep track of current active polyline and prevent mouseover/out eventhandlers from changing the style of the polyline
  const selectedRouteRef = useRef(selectedRoute);
  useEffect(() => {
    selectedRouteRef.current = selectedRoute;
  }, [selectedRoute]);

  //update marker colors, when they are set by user
  useEffect(() => {
    setMarkerColors(
      Object.fromEntries(
        Object.entries(filters.colors).map(([key, value]) => [
          key,
          value[filters.colorIndex],
        ])
      )
    );
  }, [filters.colors, filters.colorIndex]);

  const loader = new Loader({
    apiKey: MAPS_KEY, //this is a temporary key
    version: "weekly",
    libraries: ["places", "geometry", "marker"],
  });

  useEffect(() => {
    updateApiLoader(loader);
  }, [loader]);

  useEffect(() => {
    let storedCenter, storedZoom, center, zoom;

    try {
      const queryParams = new URLSearchParams(window.location.search);
      const urlFilters = queryParams.get("filters");
      const filterData = JSON.parse(decompressObjectFromURL(urlFilters));

      storedCenter = filterData.mapCenter;
      storedZoom = filterData.mapZoom;

      // Additional validation to check if the parsed data has the expected structure and type
      if (
        typeof storedCenter?.lat === "number" &&
        typeof storedCenter?.lng === "number"
      ) {
        center = storedCenter;
      } else {
        throw new Error("Invalid center format");
      }

      if (typeof storedZoom.zoom === "number") {
        zoom = storedZoom.zoom;
      } else {
        throw new Error("Invalid zoom format");
      }
    } catch (error) {
      console.error(
        "Failed to load or parse map settings from local storage:",
        error
      );
      // Fallback to default values in case of error
      center = { lat: 62.9595, lng: -1.07316 }; // Default center
      zoom = 5; // Default zoom
    }

    const onMapLoad = async () => {
      const initializedMap = await new window.google.maps.Map(
        googleMapRef.current,
        {
          center: center,
          zoom: zoom,
          minZoom: 3,
          maxZoom: 22,
          scrollwheel: true,
          zoomControl: false,
          fullscreenControl: false,
          disableDoubleClickZoom: true,
          mapTypeControl: false,
          streetViewControl: false,
          mapId: currentMapStyle.id || mapStyles.light.id,
          mapTypeId: filters.colorIndex === 3 ? "satellite" : "roadmap",
        }
      );

      // Click listener for the map
      initializedMap.addListener("click", (e) => {
        setIsConfirmationModalVisible(false);
        onSelectAirport(null);
        onSelectRoute(null);
        updateModalState(false);
        updateRouteModalState(false);
        updateSearchBarIsOpen(false);
      });

      // Right-click listener for the map
      initializedMap.addListener("rightclick", (e) => {
        setIsConfirmationModalVisible(false);
        updateSearchBarIsOpen(false);
        setContextMenu({
          visible: true,
          x: e.domEvent.clientX,
          y: e.domEvent.clientY,
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        });
      });

      //debounced map center and zoom level updates
      const debouncedUpdateCenter = debounce((center) => {
        updateLastMapCenter(center);
      }, 2000); // milliseconds to wait after the last map center change before storing the new value

      // Adding event listeners with debounced handlers
      initializedMap.addListener("center_changed", () => {
        const center = {
          lat: Number(initializedMap.getCenter().lat().toFixed(4)),
          lng: Number(initializedMap.getCenter().lng().toFixed(4)),
        };
        debouncedUpdateCenter(center);
      });

      updateMap(initializedMap);

      // Initialize a reusable OverlayView
      const overlay = new google.maps.OverlayView();
      overlay.draw = function () {}; // Required but can be a no-op
      overlay.setMap(initializedMap);

      // Save the overlay in state or ref for later use
      overlayRef.current = overlay;
      mapRef.current = initializedMap;
    };

    if (!map) {
      loader.load().then(onMapLoad);
    }
  }, [map]);

  // Update the ref for zoom when it changes
  useEffect(() => {
    if (map) {
      const debouncedUpdateZoom = debounce(() => {
        const zoom = map.getZoom();
        updateLastMapZoom({ zoom: Math.round(zoom) });
      }, 3000); // milliseconds to wait after the last zoom level change before storing the new value

      const debouncedZoomScale = debounce(() => {
        const zoom = map.getZoom();
        const zoomMultiplier = calculateMultiplier(zoom);

        // Update the zoom ref and apply the new scale
        currentZoomRef.current = zoomMultiplier;
        applyCombinedScale(); // Apply the combined scale immediately

        polylinesRef.current.forEach((polyline) => {
          if (polyline.isSelected) {
            polyline.setOptions({
              strokeWeight: 10 * zoomMultiplier, // Adjust the stroke weight based on zoom
            });
          } else {
            polyline.setOptions({
              strokeWeight: 3 * zoomMultiplier, // Adjust the stroke weight based on zoom
            });
          }
        });
      }, 200);

      map.addListener("zoom_changed", () => {
        debouncedZoomScale();
        debouncedUpdateZoom();
      });
    }
  }, [map]);

  // Function to apply the combined scale (zoomMultiplier * userSizeMultiplier)
  function applyCombinedScale() {
    if (!map) return;
    const userMultiplier = userSizeMultiplierRef.current;

    document.querySelectorAll(".markerScale").forEach((marker) => {
      const isHovered = marker.isHovered || false;
      const isSelected = marker.isSelected || false;
      const scaleMultiplier = isHovered || isSelected ? hoverMultiplier : 1;
      const combinedScale = getStrokeWeight(userMultiplier * scaleMultiplier);
      marker.style.transform = `translateY(50%) scale(${combinedScale})`;
    });
  }

  useEffect(() => {
    setGeoJsonData(handleGeoJSON());
  }, []);

  useEffect(() => {
    const initializedMap = mapRef.current;
    if (!initializedMap || !geoJsonData) return;

    // Clear previous features
    initializedMap.data.forEach((feature) =>
      initializedMap.data.remove(feature)
    );

    // Filter out countries that are in filters.country
    const filteredGeoJsonData = {
      type: "FeatureCollection",
      features: geoJsonData.features.filter(
        (feature) => !filters.country.includes(feature.properties.iso)
      ),
    };

    // Load filtered GeoJSON data
    initializedMap.data.addGeoJson(filteredGeoJsonData);

    // Apply styles
    initializedMap.data.setStyle({
      fillColor: "black",
      strokeColor: "#272727",
      strokeOpacity: 0,
      strokeWeight: 3,
      fillOpacity: 0,
    });

    // Add event listeners
    initializedMap.data.addListener("mouseover", (event) => {
      const feature = event.feature;
      initializedMap.data.overrideStyle(feature, {
        strokeOpacity: 0.8,
        fillOpacity: 0.2,
      });
    });

    initializedMap.data.addListener("mouseout", (event) => {
      const feature = event.feature;
      initializedMap.data.revertStyle(feature);
    });

    initializedMap.data.addListener("click", (event) => {
      const feature = event.feature;
      const countryIso = feature.getProperty("iso");

      // Reset all necessary states
      onSelectAirport(null);
      onSelectRoute(null);
      updateModalState(false);
      updateRouteModalState(false);
      setContextMenu({ visible: false });
      setIsConfirmationModalVisible(false);

      setGeoJsonMousePos({
        x: event.domEvent.clientX,
        y: event.domEvent.clientY,
      });

      const countryName = feature.getProperty("name");
      setSelectedCountry({ iso: countryIso, name: countryName });

      // Show confirmation modal if country is not in filters.country
      setIsConfirmationModalVisible(true);
    });

    initializedMap.data.addListener("contextmenu", (event) => {
      event.stop(); // Prevent the polygon's right-click event

      // Get the original DOM event and trigger a right-click event on the map
      const domEvent = event.domEvent || event;
      google.maps.event.trigger(initializedMap, "rightclick", {
        latLng: event.latLng,
        domEvent: {
          clientX: domEvent.clientX,
          clientY: domEvent.clientY,
        },
      });
    });

    // Cleanup on unmount
    return () => {
      initializedMap.data.forEach((feature) =>
        initializedMap.data.remove(feature)
      );
    };
  }, [filters.country, geoJsonData, map]);

  useEffect(() => {
    if (map) {
      if (
        filters &&
        filters.mapCenter &&
        filters.mapZoom &&
        filters.mapZoom.zoom
      ) {
        try {
          map.setCenter(filters.mapCenter);
          map.setZoom(filters.mapZoom.zoom);
        } catch (error) {
          console.log("Setting map center/zoom failed: ", error);
        }
      }
    }
  }, [restoreDefaultCount]);

  useEffect(() => {
    if (map) {
      const newMarkersRef = new Map();

      filteredAirportsResult?.forEach((airport) => {
        let marker = markersRef.current.get(airport.airportId);
        if (marker) {
          // Update the marker's position in case it has changed
          marker.position = new google.maps.LatLng(
            Number(airport.location.coordinates[1]),
            Number(airport.location.coordinates[0])
          );

          // Update the color
          const newColor = filters.colors[airport.type][filters.colorIndex];
          const svgElement = marker.content.querySelector("circle");
          if (svgElement) {
            svgElement.setAttribute("fill", newColor);
          }
          marker.customColor = newColor;
        } else {
          // Create new marker if it doesn't exist
          marker = addMarker(
            airport,
            map,
            onSelectAirport,
            filters.colors[airport.type][filters.colorIndex]
          );
        }
        newMarkersRef.set(airport.airportId, marker);
      });

      // Remove old markers
      markersRef.current.forEach((marker, airportId) => {
        if (!newMarkersRef.has(airportId)) {
          marker.setMap(null);
        }
      });

      markersRef.current = newMarkersRef;
    } else {
      // Clear all markers when map is null
      markersRef.current.forEach((marker) => {
        marker.setMap(null);
      });
      markersRef.current = new Map();
    }
    setMarkersState(Date.now());
  }, [
    filteredAirportsResult,
    map,
    selectedAirport,
    filters.colors,
    filters.colorIndex,
    newCustomAirportCount,
  ]);

  // Update the ref for userSizeMultiplier whenever it changes
  useEffect(() => {
    userSizeMultiplierRef.current = filters.userSizeMultiplier;

    // Apply the new userSizeMultiplier immediately
    applyCombinedScale();
  }, [filters.userSizeMultiplier]);

  useEffect(() => {
    if (map) {
      // Clear existing polylines
      polylinesRef.current.forEach((polyline) => polyline.setMap(null));
      polylinesRef.current = [];

      // Add new polylines
      filteredRoutesResult?.forEach((route) => {
        const polyline = addPolyline(route, map);
        polylinesRef.current.push(polyline);
      });
    } else {
      // Clear all polylines when map is null
      polylinesRef.current.forEach((polyline) => polyline.setMap(null));
      polylinesRef.current = [];
    }

    setPolylinesState(Date.now());
  }, [
    filteredRoutesResult,
    map,
    markerColors.domestic,
    markerColors.international,
    markerColors.customRoute,
    markerColors.commercialRoute,
    markerColors.psoRoute,
  ]);

  //close all tooltips while dragging/panning the map
  useEffect(() => {
    if (map) {
      const closeTooltips = () => setIsTooltipVisible(false);

      map.addListener("dragstart", closeTooltips);

      // remove the listener when the component unmounts or map changes
      return () => {
        google.maps.event.clearListeners(map, "dragstart");
      };
    }
  }, [map]);

  // Close UI elements when searchbar is opened
  useEffect(() => {
    if (searchBarIsOpen === true) {
      setIsConfirmationModalVisible(false);
      onSelectAirport(null);
      onSelectRoute(null);
      updateModalState(false);
      updateRouteModalState(false);
      setContextMenu({ ...contextMenu, visible: false });
    }
  }, [searchBarIsOpen]);

  //track if map is being dragged/panned
  useEffect(() => {
    if (map) {
      // Listener to set dragging state to true and hide tooltips on drag start
      const onDragStart = () => {
        setIsMapDragging(true);
        setIsTooltipVisible(false);
      };

      // Listener to reset dragging state when dragging ends
      const onDragEnd = () => {
        setIsMapDragging(false);
        setIsTooltipVisible(false);

        if (activeMarker) {
          // Delay recalculating the tooltip's position to ensure it happens after the map has settled
          setTimeout(() => {
            updateTooltipPositionForMarker(
              activeMarker.airportId,
              activeMarker.airportLocation
            );

            // Delay showing the tooltip to ensure it only appears after the position has been updated
            setTimeout(() => {
              setIsTooltipVisible(true);
            }, 50); // Adjust this delay as needed to smooth out the user experience
          }, 50); // This delay ensures map movement has settled
        }
      };

      // Listener for when the map becomes idle after movements
      const onMapIdle = () => {
        if (activeMarker) {
          // Here, updateTooltipPositionForMarker can be called without delay since we're in the idle state
          updateTooltipPositionForMarker(
            activeMarker.airportId,
            activeMarker.airportLocation,
            true
          ); // Now ready to show tooltip
        }
      };

      map.addListener("idle", onMapIdle);
      map.addListener("dragstart", onDragStart);
      map.addListener("dragend", onDragEnd);

      // Cleanup function to remove event listeners
      return () => {
        google.maps.event.clearListeners(map, "dragstart");
        google.maps.event.clearListeners(map, "dragend");
        google.maps.event.clearListeners(map, "idle");
      };
    }
  }, [map, activeMarker, isTooltipVisible]);

  useEffect(() => {
    if (activeMarker) {
      setActiveMarkerData({
        ...activeMarker,
        airportData: filteredAirportsResult.find(
          (filteredAirport) =>
            filteredAirport.airportId === activeMarker.airportId
        ),
      });
    }
  }, [activeMarker]);

  function getStrokeWeight(baseWeight) {
    const zoomMultiplier =
      currentZoomRef.current || calculateMultiplier(map.getZoom());
    return baseWeight * zoomMultiplier;
  }

  // Function to update the position of the tooltip based on the marker's position.
  function updateTooltipPositionForMarker(airportId, airportLocation) {
    if (!map || !overlayRef.current || !airportId || !airportLocation) return;

    const projection = overlayRef.current.getProjection();
    if (!projection) return;

    const point = projection.fromLatLngToDivPixel(airportLocation);
    const mapContainerRect = map.getDiv().getBoundingClientRect();
    const pixelPoint = {
      x: point.x + mapContainerRect.right / 2,
      y: point.y * -1 + mapContainerRect.bottom / 2,
    };

    setActiveMarker({
      airportId: airportId,
      airportLocation: airportLocation,
      pixelPointKey: pixelPoint,
    });
  }

  const highlightMarker = (marker) => {
    const svgElement = marker.content.querySelector("circle");
    if (svgElement) {
      const markerSvgElement = svgElement.parentElement;
      markerSvgElement.isSelected = true; // Set isSelected to true
      const svgGrandParent = svgElement.parentElement.parentElement;
      const svgGreatGrandParent = svgGrandParent.parentElement;
      svgGreatGrandParent.classList.add("selectedMarker"); // Style set in index.css

      // Increase scale of the selected marker
      const userMultiplier = userSizeMultiplierRef.current;
      const combinedScale = getStrokeWeight(userMultiplier * hoverMultiplier);
      markerSvgElement.style.transform = `translateY(50%) scale(${combinedScale})`;
    }
  };

  const resetMarkerColor = (marker) => {
    const svgElement = marker.content.querySelector("circle");
    if (svgElement) {
      const markerSvgElement = svgElement.parentElement;
      markerSvgElement.isSelected = false; // Reset isSelected to false
      const svgGrandParent = svgElement.parentElement.parentElement;
      const svgGreatGrandParent = svgGrandParent.parentElement;
      svgGreatGrandParent.classList.remove("selectedMarker"); // Style set in index.css

      // Reset scale of the marker
      const combinedScale = getStrokeWeight(userSizeMultiplierRef.current);
      markerSvgElement.style.transform = `translateY(50%) scale(${combinedScale})`;
    }
  };

  useEffect(() => {
    if (selectedAirport && selectedAirport.coordinates) {
      centerMapOnPoint(map, {
        lat: selectedAirport.coordinates[1],
        lng: selectedAirport.coordinates[0],
      });
    }

    if (selectedAirport && selectedAirport.id) {
      if (
        selectedMarkerRef.current &&
        selectedMarkerRef.current !== markersRef.current.get(selectedAirport.id)
      ) {
        resetMarkerColor(selectedMarkerRef.current);
      }
      const marker = markersRef.current.get(selectedAirport.id);

      if (marker) {
        highlightMarker(marker);
        selectedMarkerRef.current = marker;
      }
    } else {
      if (selectedMarkerRef.current) {
        resetMarkerColor(selectedMarkerRef.current);
        selectedMarkerRef.current = null;
      }
    }
  }, [selectedAirport, markersState]);

  useEffect(() => {
    if (selectedAirport && selectedAirport.id) {
      const marker = markersRef.current.get(selectedAirport.id);
      if (marker) {
        highlightMarker(marker);
        selectedMarkerRef.current = marker;
      } else {
        if (selectedMarkerRef.current) {
          resetMarkerColor(selectedMarkerRef.current);
          selectedMarkerRef.current = null;
        }
      }
    }
  }, [map]);

  const addMarker = (airport, map, onSelectAirport) => {
    const userMultiplier = userSizeMultiplierRef.current; // Get current user multiplier
    const combinedScale = getStrokeWeight(userMultiplier);

    const advancedMarkerSvg = createAdvancedMarkerSvg(
      {
        airportType: airport.type,
        letter: airport.tour ? "T" : null,
      },
      markerColors
    );

    const hoverColor = "#ffb700";

    const originalZIndex = 100; // Example baseline zIndex
    const hoverZIndex = 501; // Higher zIndex for hovered state

    const marker = new window.google.maps.marker.AdvancedMarkerElement({
      map: map,
      position: {
        lat: Number(airport.location.coordinates[1]),
        lng: Number(airport.location.coordinates[0]),
      },
      content: advancedMarkerSvg,
    });

    marker.customColor = filters.colors[airport.type][filters.colorIndex]; // Attach the current color to the marker

    marker.addListener("click", () => {
      setContextMenu({ ...contextMenu, visible: false });
      onSelectAirport({
        ...selectedAirport,
        id: airport.airportId,
        coordinates: airport.location.coordinates,
      });
      updateModalState(true); // Set click color on click
      setIsConfirmationModalVisible(false);
      updateSearchBarIsOpen(false);
    });

    // Add right-click handler for the range circle functionality
    marker.addListener("rightclick", (e) => {
      e.stop(); // Prevent the map's right-click event from firing
      setContextMenu({
        visible: true,
        x: e.domEvent.clientX,
        y: e.domEvent.clientY,
        lat: airport.location.coordinates[1], // MongoDB stores as [lng, lat]
        lng: airport.location.coordinates[0],
      });
    });

    // Store the original zIndex for easy reverting
    marker.originalZIndex = originalZIndex;
    marker.hoverZIndex = hoverZIndex;

    const markerCircle = advancedMarkerSvg.querySelector("circle");
    const markerSvgElement = markerCircle.parentElement;
    markerSvgElement.classList.add("markerScale");
    markerSvgElement.style.transform = `translateY(50%) scale(${combinedScale})`;
    markerCircle.parentElement.parentElement.parentElement.style.zIndex =
      originalZIndex;
    markerCircle.parentElement.parentElement.parentElement.classList.add(
      "markerParent"
    );

    const markerParent = markerCircle.parentElement.parentElement.parentElement;

    const showTooltip = () => {
      setIsTooltipVisible(true);
    };

    markerSvgElement.isHovered = false;
    markerSvgElement.isSelected = false;

    markerCircle.addEventListener("mouseover", () => {
      markerParent.style.zIndex = hoverZIndex;
      markerCircle.setAttribute("fill", hoverColor);
      updateTooltipPositionForMarker(airport.airportId, {
        lat: airport.location.coordinates[1],
        lng: airport.location.coordinates[0],
      });

      // Recalculate combinedScale with hover multiplier
      const userMultiplier = userSizeMultiplierRef.current;
      const combinedScale = getStrokeWeight(userMultiplier * hoverMultiplier);
      markerSvgElement.style.transform = `translateY(50%) scale(${combinedScale})`;
      markerSvgElement.isHovered = true;

      setIsMarkerActive(true);

      if (!isMapDragging) {
        setTimeout(showTooltip, 10);
      }
    });

    markerCircle.addEventListener("mouseout", () => {
      markerParent.style.zIndex = originalZIndex;
      markerCircle.setAttribute("fill", marker.customColor);
      setActiveMarker(null);
      setIsMarkerActive(false);
      setIsTooltipVisible(false);

      markerSvgElement.isHovered = false;

      // Only reset scale if not selected
      if (!markerSvgElement.isSelected) {
        const combinedScale = getStrokeWeight(userSizeMultiplierRef.current);
        markerSvgElement.style.transform = `translateY(50%) scale(${combinedScale})`;
      }
    });

    //right click event listener
    markerCircle.addEventListener("contextmenu", (e) => {
      setContextMenu({
        visible: true,
        x: e.clientX,
        y: e.clientY,
        lat: marker.position.lat,
        lng: marker.position.lng,
      });
    });
    return marker;
  };

  const highlightPolyline = (polyline) => {
    if (map && polyline) {
      polyline.setOptions({
        strokeColor: "#FFD700", // Highlight color (e.g., gold)
        strokeOpacity: 0.9,
        zIndex: 400,
        strokeWeight: getStrokeWeight(10),
      });
    }
  };

  const resetPolylineStyle = (polyline, originalValues) => {
    if (map && polyline) {
      polyline.setOptions({
        strokeColor: originalValues.color,
        strokeOpacity: originalValues.opacity,
        zIndex: originalValues.zIndex,
        strokeWeight: getStrokeWeight(originalValues.strokeWeight),
      });
    }
  };

  // SELECTED ROUTE STYLE CHANGE
  useEffect(() => {
    const previousRoute = previousSelectedRouteRef.current; // Get the previous route
    const previousPolyline = polylinesRef.current.find(
      (polyline) => polyline.routeId === previousRoute
    );

    if (selectedRoute) {
      const selectedPolyline = polylinesRef.current.find(
        (polyline) => polyline.routeId === selectedRoute
      );

      if (selectedPolyline && selectedPolyline.originalValues) {
        // Reset the previous polyline style if different
        if (previousPolyline && previousPolyline !== selectedPolyline) {
          resetPolylineStyle(previousPolyline, previousPolyline.originalValues);
          previousPolyline.isSelected = false;
        }

        // Highlight the newly selected polyline
        highlightPolyline(selectedPolyline);
        selectedPolyline.isSelected = true;

        // Update the selectedPolylineRef
        selectedPolylineRef.current = selectedPolyline;

        centerMapOnPolyline(selectedPolyline, map);
      }
    } else {
      // Reset the previous polyline style if no route is selected
      if (previousPolyline && previousPolyline.originalValues) {
        resetPolylineStyle(previousPolyline, previousPolyline.originalValues);
        previousPolyline.isSelected = false;
      }
      selectedPolylineRef.current = null;
    }

    // Update the previousRouteRef for the next effect run
    previousSelectedRouteRef.current = selectedRoute;

    // Update filters with new selected route
    updateFilters((prevFilters) => ({
      ...prevFilters,
      selectedRoute: selectedRoute,
    }));
  }, [selectedRoute, polylinesState]);

  const addPolyline = (route, map) => {
    const zoomMultiplier =
      currentZoomRef.current || calculateMultiplier(map.getZoom()); // Get current zoom scale

    const originalValues = {
      color: route.pso
        ? markerColors.psoRoute
        : route.commercial
        ? markerColors.commercialRoute
        : route.type === "custom"
        ? markerColors.customRoute
        : markerColors[route.type],
      zIndex: 200,
      opacity: 0.6,
      strokeWeight: 3 * zoomMultiplier, // Dynamic strokeWeight based on zoom
    };

    // const hoverValues = {
    //   color: "#FFD700",
    //   zIndex: 400,
    //   opacity: 0.9,
    //   strokeWeight: 10 * zoomMultiplier, // Increase stroke weight on hover, also dynamic based on zoom
    // };

    const polyline = new google.maps.Polyline({
      path: [
        {
          lat: Number(route.lonlat_1[1]),
          lng: Number(route.lonlat_1[0]),
        },
        {
          lat: Number(route.lonlat_2[1]),
          lng: Number(route.lonlat_2[0]),
        },
      ],
      strokeColor: originalValues.color,
      geodesic: true,
      strokeOpacity: originalValues.opacity,
      strokeWeight: originalValues.strokeWeight,
      map: map,
      zIndex: originalValues.zIndex,
    });

    polyline.originalValues = originalValues;
    polyline.routeId = route._id;
    polyline.isSelected = false;

    const getMousePosition = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    function handleMouseMove() {
      if (polyline.routeId === selectedRouteRef.current) return; // Do nothing if selected
      setTimeout(() => {
        if (!isMarkerActiveRef.current) {
          highlightPolyline(polyline);
        } else {
          resetPolylineStyle(polyline, originalValues);
        }
      }, 20);
    }

    //property to store the listener reference
    polyline.mouseMoveListener = null;

    polyline.addListener("mouseover", () => {
      // Update mouse position on screen
      window.addEventListener("mousemove", getMousePosition);

      // Only add the listener if it hasn't been added before
      if (!polyline.mouseMoveListener) {
        polyline.mouseMoveListener = polyline.addListener(
          "mousemove",
          handleMouseMove
        );
      }

      setActivePolyline({ ...route });
      setIsPolylineActive(true);
    });

    polyline.addListener("mouseout", () => {
      if (polyline.routeId === selectedRouteRef.current) {
        // Check if the listener reference exists before trying to remove it
        if (polyline.mouseMoveListener) {
          google.maps.event.removeListener(polyline.mouseMoveListener);
          polyline.mouseMoveListener = null; // Clear the reference
        }
        setIsPolylineActive(false);
        return; // Do nothing if selected
      }
      window.removeEventListener("mousemove", getMousePosition);
      setTimeout(() => {
        resetPolylineStyle(polyline, originalValues);
      }, 25);

      // Check if the listener reference exists before trying to remove it
      if (polyline.mouseMoveListener) {
        google.maps.event.removeListener(polyline.mouseMoveListener);
        polyline.mouseMoveListener = null; // Clear the reference
      }
      setActivePolyline(null);
      setIsPolylineActive(false);
      setCurrentPolyline(null);
    });

    polyline.addListener("rightclick", (e) => {
      setContextMenu({
        visible: true,
        x: e.domEvent.clientX,
        y: e.domEvent.clientY,
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
    });

    polyline.addListener("click", () => {
      setContextMenu({ ...contextMenu, visible: false });
      onSelectRoute(route._id);
      updateRouteModalState(true);
      updateSearchBarIsOpen(false);
    });
    return polyline;
  };

  useEffect(() => {
    if (map) {
      map.addListener("click", () => {
        updateSearchBarIsOpen(false);
        setContextMenu({ ...contextMenu, visible: false });
      });
    }
  }, [map, contextMenu]);

  function calculatePointNorthOfCenter(centerLat, centerLng, distanceKm) {
    const earthRadiusKm = 6371;
    const deltaDegrees = (distanceKm / earthRadiusKm) * (180 / Math.PI);
    const newLat = centerLat + deltaDegrees + 0.4; // Increased offset from center
    const newLng = centerLng; // Longitude remains the same when moving directly north or south
    return { lat: newLat, lng: newLng };
  }

  useEffect(() => {
    if (rangeCircle && radius) {
      const currentCenter = rangeCircle.circle.getCenter();
      const newRadius = radius * 1000; // Convert radius to meters
      rangeCircle.circle.setRadius(newRadius); // Update the circle's radius

      if (rangeCircle.distanceLabel?.content) {
        rangeCircle.distanceLabel.content.textContent = `${radius}\u00A0km`;
      }
      // Update position
      rangeCircle.distanceLabel.position = calculatePointNorthOfCenter(
        currentCenter.lat(),
        currentCenter.lng(),
        radiusRef.current
      );

      rangeCircle.circle.setVisible(true);
      rangeCircle.circle.setOptions({ zIndex: 100 });
    }
  }, [radius, rangeCircle]);

  const addRangeCircle = () => {
    if (rangeCircle) {
      rangeCircle.circle.setMap(null);
      rangeCircle.centerMarker?.setMap(null);
      rangeCircle.distanceLabel?.setMap(null);
    }
    // Create range circle
    const newRangeCircle = new window.google.maps.Circle({
      strokeColor: "#095400",
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: "#179800",
      fillOpacity: 0.35,
      map,
      center: { lat: contextMenu.lat, lng: contextMenu.lng },
      radius: radius * 1000, // convert from km to m
      zIndex: 400,
      draggable: true,
    });
    // Create range circle center marker
    const rangeCircleCenterContainer = document.createElement("div");
    rangeCircleCenterContainer.className = style.rangeCircleCenterContainer;

    const rangeCircleCenterDot = document.createElement("div");
    rangeCircleCenterDot.className = style.rangeCircleCenterDot;
    rangeCircleCenterContainer.appendChild(rangeCircleCenterDot);

    const rangeCircleCenterMarker =
      new window.google.maps.marker.AdvancedMarkerElement({
        map,
        position: { lat: contextMenu.lat, lng: contextMenu.lng },
        content: rangeCircleCenterContainer,
        zIndex: 101,
      });

    // Create range distance label
    const rangeDistanceContainer = document.createElement("div");
    rangeDistanceContainer.className = style.rangeDistanceContainer;

    const rangeDistanceText = document.createElement("div");
    rangeDistanceText.textContent = `${radius}\u00A0km`;

    rangeDistanceContainer.appendChild(rangeDistanceText);

    const rangeDistancePosition = calculatePointNorthOfCenter(
      contextMenu.lat,
      contextMenu.lng,
      radiusRef.current
    );

    const rangeDistanceLabel =
      new window.google.maps.marker.AdvancedMarkerElement({
        map,
        position: rangeDistancePosition,
        content: rangeDistanceContainer,
        zIndex: 101,
      });
    // Update positions when circle is dragged
    newRangeCircle.addListener("center_changed", () => {
      const center = newRangeCircle.getCenter();
      const updatedLabelPosition = calculatePointNorthOfCenter(
        center.lat(),
        center.lng(),
        radiusRef.current
      );

      rangeCircleCenterMarker.position = center;
      rangeDistanceLabel.position = updatedLabelPosition;
    });

    newRangeCircle.addListener("rightclick", (e) => {
      setContextMenu({
        visible: true,
        x: e.domEvent.clientX,
        y: e.domEvent.clientY,
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
    });

    setRangeCircle({
      circle: newRangeCircle,
      centerMarker: rangeCircleCenterMarker,
      distanceLabel: rangeDistanceLabel,
    });
    setContextMenu({ ...contextMenu, visible: false });
  };

  const removeRangeCircle = () => {
    if (rangeCircle) {
      rangeCircle.circle.setMap(null);
      rangeCircle.centerMarker?.setMap(null);
      rangeCircle.distanceLabel?.setMap(null);
      setRangeCircle(null);
    }
    setContextMenu({ ...contextMenu, visible: false });
  };

  const openCustomAirportForm = async () => {
    updateCustomAirportForm(false);
    updateEditingAirport(null);
    await delay(50);
    updateCustomAirportForm(true);
    setCustomAirportCoords({ lat: contextMenu.lat, lon: contextMenu.lng });
    setContextMenu({ ...contextMenu, visible: false });
  };

  const handleConfirm = () => {
    if (selectedCountry) {
      updateFilters((prevFilters) => {
        if (!prevFilters.country.includes(selectedCountry.iso)) {
          return {
            ...prevFilters,
            country: [...prevFilters.country, selectedCountry.iso],
          };
        }
        return prevFilters;
      });
    }
    setTimeout(() => {
      setIsConfirmationModalVisible(false);
    }, 250);
  };

  const handleCancel = () => {
    setTimeout(() => {
      setIsConfirmationModalVisible(false);
    }, 250);
  };

  const [currentMapStyle, setCurrentMapStyle] = useState(() => {
    // Use colorIndex from filters instead of localStorage
    const styleIndex = filters.colorIndex || 0;
    return Object.values(mapStyles)[styleIndex] || mapStyles.light;
  });

  const switchMapStyle = useCallback(
    (styleKey) => {
      const newStyle = mapStyles[styleKey];
      setCurrentMapStyle(newStyle);

      updateFilters((prev) => ({
        ...prev,
        colorIndex: newStyle.colorIndex,
      }));

      updateMap(null);
    },
    [updateMap, updateFilters]
  ); //default map style

  return (
    <>
      <div ref={googleMapRef} style={{ width: "100%", height: "100%" }} />
      {map && (
        <>
          <div className={style.searchAndUserButtonWrapper}>
            <SearchBar map={map} />
            <CustomUserButton />
          </div>

          <BottomMapControls
            currentStyle={currentMapStyle}
            onStyleChange={switchMapStyle}
            updateFilters={updateFilters}
            map={map}
          />
        </>
      )}
      <ContextMenu
        visible={contextMenu.visible}
        x={contextMenu.x}
        y={contextMenu.y}
        onAddCircle={addRangeCircle}
        onRemoveCircle={removeRangeCircle}
        updateRadius={setRadius}
        radius={radius}
        openCustomAirportForm={openCustomAirportForm}
      />
      <LoadDataConfirmationModal
        visible={isConfirmationModalVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        countryName={selectedCountry ? selectedCountry.name : ""}
        mousePosition={geoJsonMousePos}
      />
      {customAirportForm && (
        <CustomAirportForm coordinates={customAirportCoords} />
      )}
      {isTooltipVisible && !isMapDragging && isMarkerActive && (
        <MapMarkerTooltip markerData={activeMarkerData} />
      )}
      {mousePos &&
        !isMapDragging &&
        isPolylineActive &&
        airportIdMap &&
        !isMarkerActive &&
        activePolyline && (
          <PolylineTooltip
            polylineData={activePolyline}
            mousePosition={mousePos}
            airport1Data={airportIdMap.get(activePolyline.airport_1)}
            airport2Data={airportIdMap.get(activePolyline.airport_2)}
          />
        )}
    </>
  );
}
