import { createTheme } from "@mui/material/styles";
import * as variables from "../utils/variables";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//Theme for mui components

const muiTheme = createTheme({
  palette: {
    text: {
      primary: "rgb(40, 40, 40)",
    },
  },

  typography: {
    fontFamily: '"Jaldi", "Open Sans", "sans-serif"',
  },
  components: {
    MuiSwitch: {
      defaultProps: {
        size: "small", // Apply 'small' size by default to all Switch components
      },
      styleOverrides: {
        switchBase: {
          // Custom colors for the switch component when it is checked
          "&.Mui-checked": {
            color: "var(--color-primary)",
            "&:hover": {
              backgroundColor: "rgba(0, 32, 91, 0.08)",
            },
            "& + .MuiSwitch-track": {
              backgroundColor: "var(--color-primary)",
            },
            "&.Mui-disabled": {
              color: "var(--background-secondary)",
              "& + .MuiSwitch-track": {
                backgroundColor: "var(--background-secondary)",
              },
            },
          },
          "&.Mui-disabled": {
            color: "var(--background-secondary)",
            "& + .MuiSwitch-track": {
              backgroundColor: "var(--background-secondary)",
            },
          },
        },
        track: {
          backgroundColor: "rgba(0, 0, 0, 0.38)", // Default unchecked color
          "&.Mui-disabled": {
            backgroundColor: "var(--background-secondary)",
          },
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        size: "small", // Set a default size for all Slider components, if 'small' is a valid option
      },
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "& .MuiSlider-rail": {
              backgroundColor: "var(--background-secondary) !important",
            },
          },
        },
        thumb: {
          backgroundColor: "var(--color-primary)",
          "&:hover": {
            boxShadow: "0 0 0 8px rgba(0, 32, 91, 0.16)",
          },
          "&.Mui-disabled": {
            backgroundColor: "var(--background-secondary)",
          },
        },
        track: {
          backgroundColor: "var(--color-primary)",
          "&.Mui-disabled": {
            backgroundColor: "var(--background-secondary) !important",
          },
        },
        rail: {
          backgroundColor: "var(--color-primary)",
          opacity: 0.3,
          "&.Mui-disabled": {
            backgroundColor: "var(--background-secondary) !important",
            opacity: 1,
          },
        },
        valueLabel: {
          fontWeight: 700,
          backgroundColor: "var(--color-primary)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          // Apply the main color as the background color for the primary button variant
          color: "var(--text-tertiary)",
          backgroundColor: "var(--color-primary)",
          "&:hover": {
            backgroundColor: "var(--color-secondary)",
            opacity: 0.9, // Slightly lower opacity on hover
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          backgroundColor: "var(--color-primary)",
          "&:hover": {
            backgroundColor: "var(--color-secondary)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--color-primary)", // Add hover border color
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--color-primary)",
          },
          "& input[type=number]": {
            MozAppearance: "textfield",
            "&::-webkit-outer-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
            "&::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          "&.MuiFormLabel-root": {
            fontSize: "1rem",
          },
          "&.Mui-focused": {
            color: "var(--color-primary)",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none", // Remove the default divider
          },
          "&:hover, &.Mui-expanded": {
            boxShadow: "0px 2px 9px rgba(0, 0, 0, 0.3)",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            minHeight: "48px",
          },
        },
        content: {
          "&.Mui-expanded": {
            margin: "0px 0",
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: variables.mainColorDark, // Default color for the slider track
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "var(--color-primary)",
          color: "var(--text-tertiary)",
          fontFamily: '"Open Sans", "sans-serif"',
          fontSize: "0.75rem",
          padding: "0.5rem 0.5rem",
          whiteSpace: "pre-line",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          color: "var(--text-secondary)", // Text color for inactive tabs
        },
        indicator: {
          backgroundColor: "var(--background-primary)", // Color for the active tab indicator
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "var(--text-primary)", // Text color for the active tab
          },
          textTransform: "none", // Disable uppercase transformation
        },
        wrapper: {
          flexDirection: "row", // Align icon and label horizontally
        },
        icon: {
          fontSize: "1.5rem", // Change the size of the tab icon
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
        filled: {
          "&.MuiChip-filledDefault": {
            backgroundColor: "var(--color-primary)",
            opacity: 1,
            height: "1.8rem",
            WebkitFontSmoothing: "antialiased",
            MozOsxFontSmoothing: "grayscale",
            "&:hover": {
              backgroundColor: "var(--color-secondary)",
            },
            "& .MuiChip-label.MuiChip-labelMedium": {
              color: "var(--text-tertiary)",
              padding: "0.5rem",
              WebkitFontSmoothing: "antialiased",
              MozOsxFontSmoothing: "grayscale",
            },
            "& .MuiChip-deleteIcon": {
              color: "var(--background-primary)",
              fontSize: "20px",
              margin: "0 4px 0 -4px",
              "&:hover": {
                color: "var(--background-primary)",
              },
            },
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            "& *": {
              fontFamily: "inherit",
            },
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            borderRadius: "8px",
            "& .MuiAutocomplete-option:hover": {
              backgroundColor: "rgba(0, 156, 222, 0.15) !important",
              color: "var(--color-primary)",
            },
            "& .MuiAutocomplete-option[aria-selected='true']": {
              backgroundColor: "rgba(0, 156, 222, 0.15) !important",
              color: "var(--color-primary)",
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {},
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreIcon,
      },
      styleOverrides: {
        root: {
          fontWeight: 700,
          height: "48px", // Match Accordion height
        },
        icon: {
          right: "1rem", // Match the right padding
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "rgba(0, 156, 222, 0.15)",
            color: "var(--color-primary)",
          },
          "&.Mui-selected": {
            backgroundColor: "rgba(0, 156, 222, 0.15)",
            color: "var(--color-primary)",
            "&:hover": {
              backgroundColor: "rgba(0, 156, 222, 0.15)",
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiMenu-paper": {
            fontWeight: 700,
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            borderRadius: "8px",
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ExpandMoreIcon />,
      },
      styleOverrides: {
        root: {
          width: "100%",
        },
        popupIndicator: {
          right: "0.5rem",
          color: "secondary",
        },
      },
    },
  },
});

export default muiTheme;
