import { SignIn } from "@clerk/clerk-react";

import style from "../css/clerkStyling.module.css";

export function CustomSignIn() {
  return (
    <div className="custom-sign-in-container">
      <SignIn
        appearance={{
          elements: {
            formButtonPrimary: style.primaryColor,
          },
        }}
      />
    </div>
  );
}
