import { getIconSize } from "../../utils/utilityFunctions";

/**
 * Creates an advanced marker SVG element based on the provided options.
 *
 * @param {Object} options - Configuration options for the SVG marker.
 * @param {string} options.airportType - Type of the airport used to determine size and color.
 * @param {string} [options.color] - Fill color for the marker. Defaults to the color associated with the airport type.
 * @param {number} [options.opacity=1] - Opacity of the fill color.
 * @param {string} [options.strokeColor="#000"] - Stroke color for the marker's border.
 * @param {number} [options.strokeWidth=1.2] - Width of the stroke on the marker's border.
 * @param {string} [options.letter] - A letter or character to display in the center of the marker. If not provided, no text is rendered.
 * @param {Object} markerColors - An object mapping airport types to marker colors.
 *
 * @returns {SVGElement} The generated SVG element for the marker.
 */
export const createAdvancedMarkerSvg = (options, markerColors) => {
  const {
    airportType,
    color = markerColors[airportType],
    opacity = 1,
    strokeColor = "#000",
    strokeWidth = 1.2,
    letter = null,
  } = options;

  // Get the size multiplier based on the airport type
  const sizeMultiplier = getIconSize(airportType);

  // Calculate the dimensions based on the size multiplier
  const width = 14 * sizeMultiplier;
  const height = 14 * sizeMultiplier;

  const cx = width / 2;
  const cy = height / 2;
  const radius = width / 2 - 1;

  // Conditionally add a text element if a letter is provided
  const textElement = letter
    ? `<text
         x="50%"
         y="50%"
         text-anchor="middle"
         dominant-baseline="middle"
         alignment-baseline="central"
         font-size="${width / 1.8}"
         font-weight="bold"
         fill="#fff"
         stroke="#000"
         stroke-width="2"
         paint-order="stroke"
         pointer-events="none"
       >${letter}</text>`
    : "";

  const svgContent = `
    <svg class="advancedIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}" width="${width}" height="${height}">
      <circle cx="${cx}" cy="${cy}" r="${radius}" fill="${color}" fill-opacity="${opacity}" stroke="${strokeColor}" stroke-width="${strokeWidth}"/>
      ${textElement}
    </svg>
  `;

  const parser = new DOMParser();
  return parser.parseFromString(svgContent, "image/svg+xml").documentElement;
};
