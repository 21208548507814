import { useState } from "react";
import { useFilterContext } from "./ContextManager";
import { fetchServerData } from "../utils/fetchServerData";
import {
  addCustomAirportToIndexedDB,
  editCustomAirport,
} from "../utils/customAirportFunctions";

import { delay } from "../utils/utilityFunctions";

// MUI Components
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import style from "../css/customAirportForm.module.css";

export function CustomAirportForm({ coordinates }) {
  const {
    updateCustomAirportForm,
    newCustomAirportCount,
    updateNewCustomAirportCount,
    editingAirport,
    updateEditingAirport,
  } = useFilterContext();
  const [name, setName] = useState(editingAirport ? editingAirport.name : "");
  const [description, setDescription] = useState(
    editingAirport ? editingAirport.description : ""
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);
    setErrorMessage(""); // Clear any previous error messages

    try {
      if (editingAirport) {
        editCustomAirport(editingAirport.id, name, description);

        updateCustomAirportForm(false);
        updateEditingAirport(null);
        await delay(250);
        updateNewCustomAirportCount(newCustomAirportCount + 1);
      } else {
        const encodedCoords = encodeURIComponent(
          JSON.stringify({ lat: coordinates.lat, lon: coordinates.lon })
        );
        const databaseAirportsInRange = await fetchServerData(
          `/neacus?coordinates=${encodedCoords}`
        );

        await addCustomAirportToIndexedDB(
          name,
          description,
          coordinates,
          databaseAirportsInRange
        );
      }

      updateNewCustomAirportCount(newCustomAirportCount + 1);
      updateCustomAirportForm(false);
    } catch (error) {
      console.error("Failed to submit custom airport:", error);
      setErrorMessage("Failed to add custom airport. Please try again."); // Set the error message
    } finally {
      setIsSubmitting(false);
    }
  };

  const cancelCustomAirport = () => {
    updateCustomAirportForm(false);
    updateEditingAirport(null);
  };

  return (
    <>
      <div className={style.formWrapper}>
        <form className={style.customForm} onSubmit={handleSubmit}>
          <div className={style.titleErrorWrapper}>
            <p className={style.title}>
              {editingAirport ? "Edit Custom Airport" : "Custom Airport"}
            </p>{" "}
            {errorMessage && (
              <p className={style.errorMessage}>{errorMessage}</p>
            )}{" "}
            {/* Display error message */}
          </div>

          <TextField
            label="Name"
            autoComplete="off"
            size="small"
            value={name}
            onChange={handleNameChange}
            disabled={isSubmitting}
            inputProps={{ maxLength: 35 }}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="Description"
            autoComplete="off"
            multiline
            rows={4}
            value={description}
            onChange={handleDescriptionChange}
            disabled={isSubmitting}
            inputProps={{ maxLength: 350 }}
            fullWidth
            variant="outlined"
          />
          <div className={style.buttonWrapper}>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <CircularProgress size={24} />
              ) : editingAirport ? (
                "Update"
              ) : (
                "Add"
              )}
            </Button>
            <Button onClick={cancelCustomAirport} disabled={isSubmitting}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
