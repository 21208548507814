/**
 * Determines if two objects have the same keys.
 *
 * This function checks if both objects have the same number of keys and if every key in the first object
 * also exists in the second object. It does not compare the values of the keys, nor does it check for
 * key existence in the reverse direction (i.e., keys in objectB that are not in objectA).
 *
 * @param {Object} objectA - The first object to be compared.
 * @param {Object} objectB - The second object to be compared.
 * @returns {boolean} - Returns true if both objects have the same set of keys, false otherwise.
 */
export function objectsHasSameKeys(objectA, objectB) {
  return (
    Object.keys(objectA).length === Object.keys(objectB).length &&
    Object.keys(objectA).every((key) => objectB.hasOwnProperty(key))
  );
}

/**
 * returns a promise that resolves after the specified number of milliseconds.
 *
 * @param {number} ms - number of milliseconds to wait.
 * @returns {Promise<void>} promise that resolves after the specified delay.
 */
export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function getIconSize(type) {
  let size;
  switch (type) {
    case "large":
      size = 1.25;
      break;
    case "medium":
      size = 1;
      break;
    case "small":
      size = 0.8;
      break;
    case "seaplane":
      size = 1;
      break;
    case "custom":
      size = 0.8;
      break;
    default:
      size = 1; // Default size if none of the types match
  }

  return size;
}

export function airportIdMap(airportsarray) {
  let idMap = new Map();
  for (const airport of airportsarray) {
    const airportObject = {
      name: airport.name,
      country: airport.country.name,
    };
    idMap.set(airport.airportId, airportObject);
  }

  return idMap;
}

// export function timeConverter(minutes) {
//   const time = { hours: Math.floor(minutes / 60), minutes: minutes % 60 };
//   return time;
// }

export function timeConverter(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  // Create an array and conditionally add hour or minute text if they are not zero
  const parts = [];
  if (hours > 0) parts.push(`${hours}h`);
  if (mins > 0) parts.push(`${mins}m`);

  // Join the parts with a comma and space, handling cases where one of them might be zero
  return parts.join(", ");
}

/**
 * Function to modify the keys of an object based on a modification function.
 * @param {Object} obj - The original object whose keys are to be modified.
 * @param {Function} modifyFn - The function to modify each key.
 * @returns {Object} - A new object with modified keys.
 */
export function modifyKeys(obj, modifyFn) {
  // Use Object.keys to get an array of the object's own enumerable property keys
  return Object.keys(obj).reduce((acc, key) => {
    // Apply the modification function to the current key to get the modified key
    const modifiedKey = modifyFn(key);
    // Assign the value of the original key to the new modified key in the accumulator object
    acc[modifiedKey] = obj[key];
    // Return the accumulator for the next iteration
    return acc;
  }, {}); // Initialize the accumulator as an empty object
}

/**
 * Capitalizes the first letter of a given string.
 *
 * @param {string} string - The string to capitalize.
 * @returns {string} The input string with the first letter capitalized.
 */
export function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

/**
 * Calculates a zoom-based size multiplier for map markers.
 * This function scales the multiplier smoothly based on the zoom level
 * using a normalized range between a minimum and maximum zoom.
 *
 * @param {number} zoomLevel - The current zoom level of the map (typically between 3 and 22).
 * @returns {number} - The calculated zoom multiplier, where lower zoom levels return smaller values and higher zoom levels return larger values.
 */
export function calculateMultiplier(zoomLevel) {
  const minZoom = 3;
  const maxZoom = 22;

  // Normalize the zoom level between 0 and 1
  const zoomFactor = (zoomLevel - minZoom) / (maxZoom - minZoom);

  // Define the minimum and maximum size multipliers
  const minMultiplier = 0.3; // Minimum size (at zoom level 3)
  const maxMultiplier = 4; // Maximum size (at zoom level 22)

  // Return the scaled multiplier based on the zoom level
  return minMultiplier + zoomFactor * (maxMultiplier - minMultiplier);
}

/**
 * Calculates the geographic midpoint of a polyline path.
 *
 * @param {google.maps.MVCArray<google.maps.LatLng>} path - The path of the polyline as an array of LatLng points.
 * Each point should have a `lat()` and `lng()` method to get the latitude and longitude, respectively.
 *
 * @returns {{lat: number, lng: number}} The latitude and longitude of the midpoint as an object.
 */
export function calculatePolylineMidpoint(path) {
  let totalLat = 0;
  let totalLng = 0;

  path.forEach((point) => {
    totalLat += point.lat();
    totalLng += point.lng();
  });

  const midpointLat = totalLat / path.length;
  const midpointLng = totalLng / path.length;

  return { lat: midpointLat, lng: midpointLng };
}

/**
 * Smoothly centers the map on the polyline's midpoint and adjusts the zoom level based on the polyline's length.
 *
 * @param {google.maps.Polyline} polyline - The polyline to highlight.
 * @param {google.maps.Map} map - The Google Map instance.
 */
export function centerMapOnPolyline(polyline, map) {
  if (map && polyline) {
    const path = polyline.getPath();

    // Calculate the length of the polyline in meters
    const lengthInMeters = google.maps.geometry.spherical.computeLength(path);
    const lengthInKm = lengthInMeters / 1000;

    // Compute the appropriate zoom level
    const zoomLevel = calculateZoomLevel(lengthInKm);

    // Get the midpoint of the polyline
    const midpoint = calculatePolylineMidpoint(path.getArray());

    // Smoothly pan to the midpoint and set the zoom level
    map.panTo(midpoint);
    map.setZoom(zoomLevel);
  }
}

/**
 * Calculates an appropriate zoom level based on the length of the polyline.
 *
 * @param {number} lengthInKm - The length of the polyline in kilometers.
 * @returns {number} - The calculated zoom level.
 */
function calculateZoomLevel(lengthInKm) {
  const maxZoomLevel = 15; // Closest zoom
  const minZoomLevel = 7; // Farthest zoom

  // Clamp the length to avoid extreme zoom levels
  const clampedLength = Math.max(1, Math.min(lengthInKm, 400));

  // Use a logarithmic scale for a smoother zoom transition
  const zoomLevel =
    maxZoomLevel -
    ((Math.log(clampedLength) - Math.log(1)) / (Math.log(400) - Math.log(1))) *
      (maxZoomLevel - minZoomLevel);

  return Math.round(zoomLevel);
}

/**
 * Smoothly centers the map on a single point and adjusts the zoom level.
 *
 * @param {google.maps.Map} map - The Google Map instance.
 * @param {google.maps.LatLng | {lat: number, lng: number}} coordinates - The coordinates to center the map on.
 * @param {number} [zoomLevel=6] - The zoom level to set when centering the map.
 */
export function centerMapOnPoint(map, coordinates, zoomLevel = 7) {
  if (map && coordinates) {
    // Center the map on the provided coordinates
    map.panTo(coordinates);

    // Set the specified zoom level
    map.setZoom(zoomLevel);
  }
}

/**
 * Creates a map of airports with their route counts initialized to 0.
 *
 * @param {Array<Object>} filteredAirports - The list of filtered airport objects.
 * Each airport object should contain a valid `airportId` property.
 * @returns {Object} A map where each key is an airport ID, and the value is initialized to 0.
 *
 * @throws Will log an error if `filteredAirports` is not an array.
 * @throws Will log a warning if an airport object lacks a valid `airportId`.
 */
export function createAirportRouteCountMap(filteredAirports) {
  const airportRouteMap = {};

  // Check if filteredAirports is a valid array
  if (!Array.isArray(filteredAirports)) {
    console.error("Invalid input: 'filteredAirports' should be an array.");
    return airportRouteMap;
  }

  filteredAirports.forEach((airport) => {
    // Ensure airport has a valid airportId
    if (airport && typeof airport.airportId === "string") {
      airportRouteMap[airport.airportId] = 0;
    } else {
      console.warn(
        "Skipped an airport with invalid or missing 'airportId':",
        airport
      );
    }
  });

  return airportRouteMap;
}
