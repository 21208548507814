import {
  compressToEncodedURIComponent,
  decompressFromEncodedURIComponent,
} from "lz-string";

// Define a mapping of human-readable filter keys to their corresponding short codes. If new entries are added to filterSchema they need to be added to filterCodes with a unique code as its value
const filterCodes = {
  minDistance: "MD",
  maxDistance: "MX",
  minRunwayLength: "RL",
  maxRunwayLength: "RM",
  type: "TY",
  country: "CY",
  routeType: "RT",
  scheduledService: "SS",
  colors: "CL",
  colorIndex: "CI",
  timeSaved: "TS",
  timeSavedActive: "TA",
  timeSavedValue: "TV",
  ratioActive: "RA",
  ratioValue: "RV",
  speed: "SP",
  departure: "DP",
  arrival: "AR",
  travelType: "TT",
  mapCenter: "MC",
  mapZoom: "MZ",
  activeAirport: "AA",
  selectedRoute: "SR",
  commercial: "CM",
  pso: "PS",
  airportPax: "AP",
  routesPax: "RP",
  custom: "CU",
  userSizeMultiplier: "UM",
};

//Reverses an object's entries, the returned object has the original object's keys as its values and it's values as keys
function reverseObjectEntries(originalObject) {
  let reversedObject = {};
  for (const key in originalObject) {
    reversedObject[originalObject[key]] = key;
  }
  return reversedObject;
}

// Reverse mapping from short codes to original keys, computed once for efficiency
const reversedFilterCodes = reverseObjectEntries(filterCodes);

// Compress and map keys to their corresponding short codes for URL storage
export function compressObjectForURL(obj) {
  let compressedObj = {};

  // Apply general key shortening to all keys including transformed 'type', 'routeType', and 'colors'
  for (const key in obj) {
    compressedObj[filterCodes[key] || key] = obj[key];
  }

  const jsonString = JSON.stringify(compressedObj);
  return compressToEncodedURIComponent(jsonString);
}

// Decompress URL data and map short codes back to their original keys
export function decompressObjectFromURL(compressedString) {
  let expandedObj = {};

  try {
    const jsonString = decompressFromEncodedURIComponent(compressedString);
    const decompressedObj = JSON.parse(jsonString);

    // First expand all keys using reversedFilterCodes
    for (const key in decompressedObj) {
      const originalKey = reversedFilterCodes[key] || key; // Find original key name
      expandedObj[originalKey] = decompressedObj[key];
    }
  } catch (error) {
    console.error("Failed to decompress and parse URL data:", error);
    // If there's an error in decompression or parsing, set expandedObj to an empty object
    expandedObj = {};
  }

  return JSON.stringify(expandedObj); // Return the possibly empty expanded object
}
