import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { submitFeedback } from "../utils/submitFeedback";
import { SuccessMessage } from "./FeedbackModalSuccess";
import style from "../css/modalDisclaimerFeedback.module.css";

export const FeedbackModal = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [feedbackStatus, setFeedbackStatus] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsSubmitted(false);
    setName("");
    setEmail("");
    setMessage("");
    setFeedbackStatus("");
    setIsSubmitting(false);
  };

  const handleSuccessClose = () => {
    setIsSubmitted(false);
    setOpen(false);
    setName("");
    setEmail("");
    setMessage("");
    setFeedbackStatus("");
  };

  // Email validation regex pattern
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Clear feedback status briefly to refresh the error message
    setFeedbackStatus("");

    // Perform validation and submission logic after a brief delay
    setTimeout(async () => {
      if (!name || !email || !message) {
        setFeedbackStatus("Please fill in all the fields!");
        return;
      }

      if (!emailPattern.test(email)) {
        setFeedbackStatus("Please enter a valid email address!");
        return;
      }

      const feedback = { name, email, message };
      setFeedbackStatus(""); // Clear feedback status before submitting
      setIsSubmitting(true);

      try {
        await submitFeedback(feedback);
        setIsSubmitted(true);
        setName("");
        setEmail("");
        setMessage("");
        setFeedbackStatus("");
      } catch (error) {
        setFeedbackStatus(`Failed to submit feedback: ${error.message}`);
      } finally {
        setIsSubmitting(false);
      }
    }, 50);
  };

  return (
    <div>
      <p onClick={handleOpen}>Feedback</p>
      <Modal
        open={open && !isSubmitted}
        onClose={handleClose}
        sx={{ backdropFilter: "blur(0.2rem)" }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={style.modal}>
          <div className={style.header}>
            <h1>Feedback</h1>
            <IconButton
              className={style.closeButton}
              aria-label="close menu"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>

          <form onSubmit={handleSubmit} className={style.form} noValidate>
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              margin="normal"
              autoComplete="off"
            />
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
              autoComplete="off"
            />
            <TextField
              label="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              autoComplete="off"
            />
            <div className={style.submitContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={style.submitButton}
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Submit"}
              </Button>
              {feedbackStatus && (
                <div className={style.feedbackStatus}>{feedbackStatus}</div>
              )}
            </div>
          </form>
        </Box>
      </Modal>
      <SuccessMessage open={isSubmitted} handleClose={handleSuccessClose} />
    </div>
  );
};
