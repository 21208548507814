import { useEffect, useState } from "react";
import { useFilterContext } from "./ContextManager";
import { TruncatedText } from "./UX/TruncatedText/TruncatedText";
import {
  getStoredCustomAirports,
  deleteAirportAndUpdateRoutes,
} from "../utils/customAirportFunctions";

import { delay } from "../utils/utilityFunctions";

//MUI components
import IconButton from "@mui/material/IconButton";

//icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import style from "../css/customAirportList.module.css";

export function CustomAirportList() {
  const {
    updateCustomAirportForm,
    newCustomAirportCount,
    updateNewCustomAirportCount,
    updateEditingAirport,
  } = useFilterContext();

  const [currentCustomAirports, setCurrentCustomAirports] = useState([]);

  useEffect(() => {
    const getListOfCustomAirports = async () => {
      const allCurrentAirportRecords = await getStoredCustomAirports();

      // Sort airports by extracting numbers from their IDs
      allCurrentAirportRecords.sort((a, b) => {
        const numA = parseInt(a.id.split("-")[2]); // Assuming the format is always "CST-AP-X"
        const numB = parseInt(b.id.split("-")[2]);
        return numA - numB;
      });

      const reducedResults = allCurrentAirportRecords.map((record) => ({
        id: record.id,
        name: record.airport.name,
        description: record.airport.description,
      }));

      setCurrentCustomAirports(reducedResults);
    };

    getListOfCustomAirports();
  }, [newCustomAirportCount]);

  const handleEdit = async (airport) => {
    // Set the data for the form and open it
    updateCustomAirportForm(false);
    updateEditingAirport(airport); // Store airport in context so it can be used by the form
    await delay(100);
    updateCustomAirportForm(true); // Show CustomAirportForm
  };

  const handleDelete = async (airportId) => {
    await deleteAirportAndUpdateRoutes(airportId);
    updateNewCustomAirportCount(newCustomAirportCount + 1);
  };

  return (
    <>
      {currentCustomAirports.length > 0 ? (
        <div className={style.wrapper}>
          {currentCustomAirports.map((airport, i) => (
            <div key={airport.id}>
              <div className={style.airportMain}>
                <TruncatedText
                  text={airport.name}
                  maxWidthPx={200}
                  elementClass={style.name}
                />
                <div className={style.icons}>
                  <IconButton onClick={() => handleEdit(airport)}>
                    <EditIcon className={style.icon} />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(airport.id)}>
                    <DeleteIcon className={style.icon} />
                  </IconButton>
                </div>
              </div>
              {i !== currentCustomAirports.length - 1 && (
                <div className={style.line}></div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className={style.emptyListMessage}>
          Right-click on the map to add your own custom airports.
        </p>
      )}
    </>
  );
}
