import { useMemo, useState, useEffect } from "react";

import priceDataJson from "/src/data/price_data.json";
import PriceChart from "./PriceChart";

import style from "../css/modalAirportsRoutes.module.css";

import { Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const PriceData = ({
  route,
  airport1Details,
  airport2Details,
  onExpandChange,
}) => {
  const [isPriceDataOpen, setIsPriceDataOpen] = useState(false);

  // Price data logic
  const priceDataForRoute = useMemo(() => {
    if (!route) return null;
    const routeId1 = `${route.airport_1}_${route.airport_2}`;
    const routeId2 = `${route.airport_2}_${route.airport_1}`;

    const priceEntry = priceDataJson.find(
      (entry) => entry._id === routeId1 || entry._id === routeId2
    );

    if (!priceEntry) return null;

    return {
      [routeId1]: priceEntry[routeId1] || null,
      [routeId2]: priceEntry[routeId2] || null,
    };
  }, [route]);

  // Event handler
  const togglePriceDataOpen = () => {
    const isExpanded = !isPriceDataOpen;
    setIsPriceDataOpen(isExpanded);
    onExpandChange?.(isExpanded);
  };

  // When component unmounts or route changes, ensure modal is small
  useEffect(() => {
    return () => {
      onExpandChange?.(false);
    };
  }, [onExpandChange]);

  // When route changes, close price data
  useEffect(() => {
    setIsPriceDataOpen(false);
    {
    }
    onExpandChange?.(false);
  }, [route, onExpandChange]);

  if (!priceDataForRoute) {
    return null;
  }

  return (
    <div>
      <div
        className={style.routeCollapseContainer}
        onClick={togglePriceDataOpen}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            togglePriceDataOpen();
          }
        }}
      >
        <div className={style.routeCollapseHeader}>
          <div>Price Data</div>
          <div className={style.routeCollapseHeaderValue}>
            <ExpandMoreIcon
              className={`${style.routeIcon} ${
                isPriceDataOpen ? style.routeIconRotate : ""
              }`}
            />
          </div>
        </div>
      </div>
      <Collapse in={isPriceDataOpen} timeout={400}>
        <div
          className={style.routeCollapseContent}
          style={{
            visibility: isPriceDataOpen ? "visible" : "hidden",
            transition: "visibility 0s linear 0.3s",
          }}
        >
          {priceDataForRoute[`${route.airport_1}_${route.airport_2}`] && (
            <div
              className={`${style.routeChartWrapper} ${
                isPriceDataOpen ? style.routeChartVisible : ""
              }`}
            >
              <h5>{`${airport1Details.name} → ${airport2Details.name}`}</h5>
              <PriceChart
                data={
                  priceDataForRoute[`${route.airport_1}_${route.airport_2}`]
                }
              />
            </div>
          )}
          {priceDataForRoute[`${route.airport_2}_${route.airport_1}`] && (
            <div
              className={`${style.routeChartWrapper} ${
                isPriceDataOpen ? style.routeChartVisible : ""
              }`}
            >
              <h5>{`${airport2Details.name} → ${airport1Details.name}`}</h5>
              <PriceChart
                data={
                  priceDataForRoute[`${route.airport_2}_${route.airport_1}`]
                }
                id={route._id}
              />
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};
