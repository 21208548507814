import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import style from "../css/modalDisclaimerFeedback.module.css";

export const DisclaimerModal = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <p onClick={handleOpen}>Disclaimer</p>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{
          backdrop: {
            className: style.backdrop,
          },
        }}
      >
        <Box className={style.modal}>
          <div className={style.header}>
            <h1>Disclaimer</h1>
            <IconButton
              aria-label="close menu"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={style.mainTextWrapper}>
            <div className={style.text}>
              The information provided on this website, including airport
              details and routes, is for general informational purposes only.
              While we strive to keep the information up to date and correct, we
              make no representations or warranties of any kind, express or
              implied, about:
            </div>
            <ul className={style.list}>
              <li className={style.listItem}>
                The completeness, accuracy, or reliability of the information.
              </li>
              <li className={style.listItem}>
                The suitability or availability of the website or the
                information, products, services, or related graphics contained
                on the website for any purpose.
              </li>
              <li className={style.listItem}>
                The uninterrupted, error-free, or secure operation of the
                website.
              </li>
              <li className={style.listItem}>
                The correction of any defects or errors on the website.
              </li>
            </ul>
            <div className={style.text}>
              Any reliance you place on such information is therefore strictly
              at your own risk. We are not responsible for:
            </div>
            <ul className={style.list}>
              <li className={style.listItem}>
                Any errors or omissions in the information provided.
              </li>
              <li className={style.listItem}>
                The results obtained from the use of this information.
              </li>
              <li className={style.listItem}>
                Any technical issues or temporary unavailability of the website.
              </li>
              <li className={style.listItem}>
                Any actions taken based on the content of this website.
              </li>
            </ul>
            <div className={style.text}>
              All information is provided "as is" with no guarantee of
              completeness, accuracy, timeliness, or the results obtained from
              the use of this information. In no event will we be liable for any
              loss or damage, including without limitation:
            </div>
            <ul className={style.list}>
              <li className={style.listItem}>
                Indirect or consequential loss or damage.
              </li>
              <li className={style.listItem}>
                Any loss or damage whatsoever arising from loss of data or
                profits arising out of, or in connection with, the use of this
                website.
              </li>
              <li className={style.listItem}>
                Any damages arising from the use or inability to use the
                website, including but not limited to viruses, system failures,
                or other technological malfunctions.
              </li>
            </ul>
            <div className={style.text}>
              Through this website, you are able to link to other websites which
              are not under our control. We have no control over the nature,
              content, and availability of those sites. The inclusion of any
              links does not necessarily imply a recommendation or endorsement
              of the views expressed within them. We are not responsible for:
            </div>
            <ul className={style.list}>
              <li className={style.listItem}>
                The content, privacy policies, or practices of any third-party
                websites.
              </li>
              <li className={style.listItem}>
                Any consequences arising from visiting or using third-party
                websites linked from this website.
              </li>
              <li className={style.listItem}>
                The accuracy or reliability of the information provided on
                third-party websites.
              </li>
            </ul>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
