import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import style from "../css/modalDisclaimerFeedback.module.css";

export const SuccessMessage = ({ open, handleClose }) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box className={`${style.modal} ${style.successModal}`}>
      <div className={style.header}>
        <h2>Successfully submitted!</h2>
        <IconButton
          className={style.closeButton}
          aria-label="close menu"
          size="small"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <p className={style.successMessage}>Thank you for your feedback.</p>
    </Box>
  </Modal>
);
